<template>
  <div class="">
    <div class="d-flex mb-3 align-items-center justify-content-between">
      <div
        class="v16-description"
        style="
          color: #73738d;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        "
      >
        Media
      </div>
      <button class="export-button" @click="openImageModal">
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(-1, 0, 0, 1, 0, 0)"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M14.2647 15.9377L12.5473 14.2346C11.758 13.4519 11.3633 13.0605 10.9089 12.9137C10.5092 12.7845 10.079 12.7845 9.67922 12.9137C9.22485 13.0605 8.83017 13.4519 8.04082 14.2346L4.04193 18.2622M14.2647 15.9377L14.606 15.5991C15.412 14.7999 15.8149 14.4003 16.2773 14.2545C16.6839 14.1262 17.1208 14.1312 17.5244 14.2688C17.9832 14.4253 18.3769 14.834 19.1642 15.6515L20 16.5001M14.2647 15.9377L18.22 19.9628M18.22 19.9628C17.8703 20 17.4213 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.7157 19.5903 4.40973 19.2843 4.21799 18.908C4.12583 18.7271 4.07264 18.5226 4.04193 18.2622M18.22 19.9628C18.5007 19.9329 18.7175 19.8791 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V13M11 4H7.2C6.07989 4 5.51984 4 5.09202 4.21799C4.7157 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.0799 4 7.2V16.8C4 17.4466 4 17.9066 4.04193 18.2622M18 9V6M18 6V3M18 6H21M18 6H15"
              stroke="#73738D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
        Add
      </button>
    </div>
    <div class="card border-radius">
      <div class="card-body">
        <div class=""  v-if="images.length > 0">
        <div
          v-if="selectedProductImage.length > 0"
          class="d-flex align-items-center justify-content-between"
        >
          <b-form-checkbox
            v-model="toggleSelectAll"
            @change="selectAllHandle()"
            class="checkbox"
          >
            Select All
          </b-form-checkbox>
          <div class="d-flex align-items-center">
            <span
              class="text-danger d-flex align-items-center"
              v-if="isLoadingDeleteImage"
            >
              <b-spinner small class="mr-2"></b-spinner>
              Deleting...
            </span>

            <div
              class="btn btn-link text-danger"
              v-else
              @click="$emit('delete')"
            >
              Delete
            </div>
          </div>
        </div>

        <div class="">
          <draggable
            v-model="getImages"
            draggable=".draggableItems"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="isDragging = false"
          >
          <transition-group
 
  type="transition"
  class="gridContainer"
  :name="!isDragging ? 'flip-list' : null"
>
  <div
    class="m-1 draggableItems"
    style="cursor: move"
    v-for="(image,index) in images"
    :key="image.image||index"
  >
    <div class="card w-100 h-100">
      <div class="p-2 w-100 h-100">
        <div
          class="gallery-image w-100 h-100"
          style="position: relative"
        >
          <lazy-image
            class="w-100 h-100"
            style="object-fit: cover; border-radius: 6px"
            :src="checkImage(image)"
            alt=""
          />
        </div>
        <div
          :class="[
            selectedProductImage.includes(image) ? 'd-block' : '',
          ]"
          class="gallery-tools align-items-between p-2"
        >
          <div class="d-flex justify-content-between">
          <div class="w-100">
            <b-form-checkbox
              :value="image"
              size="lg"
              v-model="selectedProductImage"
              class="checkbox"
            />
          </div>
          <div class="">
            <i class="fa p-2 fa-align-justify handle" style="color:white"></i>
          </div>
        </div>
        <div v-if="index==0" class="d-flex  justify-content-center text-white align-items-center">
          Thumbnail
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :key="'extra-element'"></div>
</transition-group>

          </draggable>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center" v-else>
        <EmptyTable :showButton="true" @click="openImageModal" btnTitle="Upload Image">
          <template #title>
    No image uploaded
  </template>
  <template #description>
    Please click 'Upload Image' to choose and add one.
  </template>
        </EmptyTable>
      </div>
    </div>
    </div>
    <Modal id="addImage" ref="addImage" title="Add Image" size="md">
      <div class="px-3">
      <div class="d-flex align-items-center menu mb-3">
          <div :class="[activeModalTab=='upload'?'active':'']"  @click="activeModalTab='upload'"
          >Upload Image</div>
                        <div :class="[activeModalTab=='url'?'active':'']"  @click="activeModalTab='url'">Add from URL</div>
                    </div>
                    <div class="">
        <div class=""><b-alert variant="danger" :show="error?true:false">{{ error }}</b-alert></div>

                      <div class="content-drag-drop" v-if="activeModalTab=='upload'">
                        <div v-if="isFileSelected" class="">
                        <div class="v16-description">Preview:</div>
              <div  class="block-add-products border flex-column d-flex justify-content-center align-items-center" style="font-size: 40px;">
                
                <lazy-image class="w-100 h-100"
      style="object-fit: cover; border-radius: 6px" :src="imageURL"></lazy-image>

    </div>
  </div>
            <div v-else class="block-add-products  d-flex justify-content-center align-items-center" @click="$refs.openFile.click()"
            @dragover.prevent
      @dragenter="isDragging=true"
      @dragleave="isDragging = false"
      @drop.prevent="handleFileDrop"
            :style="{border: isDragging?'2px dashed #bfbfc0':'1px solid #bfbfc0'}"
            >
            <!-- @dragenter="isDragging=true"
            @dragleave="isDragging=false" -->
                <div  v-if="isDragging" class="h5" style="text-align:center"> <i class="fa fa-upload py-2"  aria-hidden="true"></i> <div>Drop Your File Here</div></div>
                <div v-else class="add-btn text-center">
                   Add Image
                </div>
                <input type="file" name="" id="" class="d-none" @change="handleFileUpload" accept="image/png, image/jpg, image/jpeg, image/gif" ref="openFile">
            </div>
            <div v-if="isFileSelected" class="d-flex justify-content-center mt-3 align-items-center gap">
              <button @click="reset" class="export-button py-2 px-3"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M2.63263 11.243C3.11896 12.6233 4.04073 13.8081 5.25906 14.619C6.47739 15.4299 7.92627 15.8229 9.3874 15.7388C10.8485 15.6548 12.2427 15.0981 13.36 14.1528C14.4772 13.2075 15.257 11.9247 15.5817 10.4978C15.9065 9.07081 15.7586 7.57697 15.1605 6.24132C14.5623 4.90568 13.5463 3.8006 12.2654 3.09258C10.9846 2.38455 9.5083 2.11195 8.05903 2.31584C5.61219 2.66007 3.9956 4.43357 2.25 6M2.25 6V1.5M2.25 6H6.75" stroke="#73738D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Reset </button>
              <Button :isLoading="isImageUploadLoading" @click="uploadFileImage()">Upload</Button>
            </div>
        </div>
        <div class="content-drag-drop"  v-else>
          <div class="" style="height:200px">
           
            <div class="v16-description">Image URL:</div>
            <div class="v-16-input-control"><input type="url" v-model="imageURL" class="mx-2 flex-grow-1 overflow-hidden"></div>

          </div>
          <div  class="d-flex justify-content-center mt-3 align-items-center gap">
              <button @click="reset" class="export-button py-2 px-3"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M2.63263 11.243C3.11896 12.6233 4.04073 13.8081 5.25906 14.619C6.47739 15.4299 7.92627 15.8229 9.3874 15.7388C10.8485 15.6548 12.2427 15.0981 13.36 14.1528C14.4772 13.2075 15.257 11.9247 15.5817 10.4978C15.9065 9.07081 15.7586 7.57697 15.1605 6.24132C14.5623 4.90568 13.5463 3.8006 12.2654 3.09258C10.9846 2.38455 9.5083 2.11195 8.05903 2.31584C5.61219 2.66007 3.9956 4.43357 2.25 6M2.25 6V1.5M2.25 6H6.75" stroke="#73738D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Reset </button>
              <Button :isLoading="isLoadImageAdding" @click="uploadURLImage()">Upload</Button>
            </div>
        </div>
                    </div>
                  </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "../../Layout/Modal.vue";
import Button from "../../Layout/Button.vue";
import draggable from "vuedraggable";
import EmptyTable from '../../Layout/EmptyTable.vue';
export default {
  components: {
    Button,
    draggable,
    Modal,
    EmptyTable
  },
  watch: {
    getSelectedInfo: {
      handler({ selected }) {
        this.toggleSelectAll = this.images.every((image) =>
          selected.includes(image)
        )
          ? true
          : false;
        this.$emit("selectedImages", selected);
      },
      deep: true,
    },
    images: {
      handler(newValue) {
        if (newValue.length <= 0) this.toggleSelectAll = false;
      },
      deep: true,
    },
  },
  props: {
  uploadImage:Function,
  uploadImageLink:Function,
    images: Array,

    isLoadingDeleteImage: {
      type: Boolean,
      default: false,
    },
    isLoadImageAdding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isImageUploadLoading:false,
      file:null,
      imageURL:'',
      isFileSelected:false,
      error:'',
      isDragging: false,
      selectedProductImage: [],
      toggleSelectAll: false,
      dragOptions: {
        onStart: () => {
          this.isDragging = true;
        },
        onEnd: () => {
          this.isDragging = false;
        },
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      },
      activeModalTab:'upload'
    };
  },

  computed: {
  
    getImages: {
      get() {
        return this.images;
      },
      set(newValue) {
        this.$emit("updateImageDrag", newValue);
      },
    },
    getSelectedInfo() {
      return { selected: this.selectedProductImage };
    },
    getFirstImage() {
      return this.images.length > 0 ? this.images[0] : [];
    },
  },
  methods: {
    async uploadURLImage(){
      this.isLoadingImageURL=true;
      this.error=''
      // eslint-disable-next-line no-useless-escape
      const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      if(!urlRegex.test(this.imageURL)){
        this.error="Invalid URL."
        return
      }
      await this.uploadImageLink(this.imageURL)
      this.isLoadingImageURL=false;
      this.$refs.addImage.close()
    },
    reset(){
      this.activeModalTab='upload'
      this.imageURL=''
      this.file=null
      this.isFileSelected=false
      this.error=''
    },
    openImageModal(){
      this.activeModalTab='upload'
      this.imageURL=''
      this.file=null
      this.isFileSelected=false
      this.error=''
      this.$refs.addImage.open()
    },
   async uploadFileImage(){
    this.isImageUploadLoading=true;
      await this.uploadImage(this.file);
      this.isImageUploadLoading=false;
      this.$refs.addImage.close()

      
    },
    handleFileDrop(event) {
          this.isDragging = false;
          // Handle the dropped file here
          const files = event.dataTransfer.files;
          if (files.length > 0) {
            this.handleFileUpload(files[0],true)
            // Do something with the dropped file(s)
            
          }
        },
    async  handleFileUpload(event,fromFromDrag=false) {
          this.error=null;
          let file= null
          if(fromFromDrag)
         file=event
          else
        file = event.target.files[0];
      this.file=file;
      if (file) {
        
        const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        if (!allowedFileTypes.includes(file.type)) {
          this.error = 'Invalid file type. Please select an image (PNG, JPEG, JPG, GIF)';
          return;
        }
        this.isFileSelected=true
        this.file=event;
       
        this.imageURL = window.URL.createObjectURL(file);
      }
    },
    checkImage(image) {
      if (image.image) return image.image;
      else return image.url;
    },
    selectAllHandle() {
      this.selectedProductImage = this.toggleSelectAll ? this.images : [];
    },
 
  }
};
</script>
<style scoped>
.gallery-image:hover + .gallery-tools,
.gallery-tools:hover {
  display: block;
}
.gallery-tools {
  display: none;
  z-index: 1111;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  height: 100%;
  border-radius: 3px;
  background-color: rgba(1, 1, 1, 0.5);
  transition:0.3s ease all;
}
.image-ghost {
  z-index: 1111;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  height: 100%;
  border-radius: 3px;
  background-color: rgba(1, 1, 1, 0.2);
  border: 3px dotted rgb(161, 160, 160);
}
.gallery-tool-button {
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  background: transparent;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary);
  background-color: var(--secondary) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary);
}
.text-quizell {
  color: #4d1b7e;
}
.h-w-170 {
  height: 170px;
  width: 170px;
}
.h-w-150 {
  height: 150px;
  width: 150px;
}
.h-w-370 {
  height: 370px;
  width: 275px;
}
.h-w-350 {
  height: 350px;
  width: 250px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.ghost {
  position: fixed;
  opacity: 0.5;
  pointer-events: none;
  z-index: 1000;
  /* Additional styling for the ghost element */
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 10px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
.draggableItems{
  height: 120px;
}
/* .gridContainer .draggableItems:first-child {
  grid-area: 1 / 1 / span 2 / span 2;
  min-height: 350px;
} */
.menu{
    gap:20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 15px;
}
.menu div{
    color: hsl(240, 10%, 50%);
cursor:pointer;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
text-decoration: none;
position: relative;
}
.menu div.active{
    color:var(--primary-color)
}
.menu div.active:after{
    display: block;
    content: '';
    position: absolute;
    bottom: -15px;
    height: 2px;
    /* margin-bottom: 20px; */
    width: 100%;
    background: var(--primary-color);
    border-radius: 4px 4px 0px 0px;
}
.add-btn{
    background: #fdfdfd;
    border-radius: 12px;
    color: #303030;
    box-shadow: inset 0rem -0.0625rem 0rem #b5b5b5,inset -0.0625rem 0rem 0rem #e3e3e3,inset 0.0625rem 0rem 0rem #e3e3e3,inset 0rem 0.0625rem 0rem #e3e3e3;
    padding: 0.375rem 0.75rem;
}
.block-add-products{
    width: 100%;
    height: 250px;
    border-radius: 8px;
    cursor: pointer;
}
.block-add-products:hover{
background: #f9f9f9;
}
</style>
